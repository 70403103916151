.ewa-sidebar-item {
  opacity: 1;
  color: #fff;

  &:hover, &.-active {
    opacity: 0.8;
  }

  .ewa-icon {
    &.-genres:before,
    &.-courses:before,
    &.-phrases:before,
    &.-settings:before,
    &.-referral-programs:before {
      background-color: #fff;
    }

    &.-learning:before,
    &.-materials:before {
      color: #fff;
    }
  }
}

.ewa-topbar-search-form input {
  color: #000;

  &::placeholder {
    color: #ccc;
  }
}

.ewa-widebar {
  color: $color-fill;
  background-color: transparentize($color-primary, .2);
}
