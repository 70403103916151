.non-clickable {
    pointer-events: none;
    cursor: default
}

.courses-edit-select {
    background-color: #fff;
    background-image: none;
    border: none;
    font-size: 15px;
    border-bottom: 1px solid hsla(220, 5%, 87%, .3);
    box-shadow: none;
    height: 34px;
    float: right;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.ewa-content {
    .breadcrumbs {
        &.courses {
            position: relative;
            bottom: 15px;
        }
    }
    &.courses {
        padding-top: 7rem;
    }
}

.learning-head {
    margin-bottom: 23px;
    .name {
        font-size: 18px;
        line-height: 45px;
        margin-right: 40px;
    }
    .actions > div {
        margin-right: 5px;
        position: relative;
    }
}

ul {
    padding: 0;

    li {
        &.bounded {
            outline: solid 1px #E040FB;
            outline-offset: 5px;
        }

        list-style-type: none;
    }
}

.learning-card {

    position: relative;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    &-inner {
        background: #fff;
        padding: 30px 20px;

        .index {
            margin-right: 100px;
            font-size: 24px;
            span {
                margin-right: 5px;
            }
        }

        .name {
            border: 0;
            outline: 0;
            color: #D0021B;
            font-size: 24px;
        }

        .role {
            border: 0;
            outline: 0;
            color: #000000;
            font-size: 24px;
            margin-right: 20px;
        }

        .-pencil {
            &:before {
                position: relative;
                top: 5px;
                font-size: 24px;
                cursor: pointer;
                color: $color-primary;
                &:hover {
                    color: $color-secondary;
                }
            }
        }

        .exports {
            margin-top: 20px;
            div {
                margin-right: 5px;
            }
        }
        .stats {
            margin-top: 25px;
            &-item {
                display: inline-block;
                margin-right: 15px;
                .stat-name {
                    color: #152935;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-right: 15px;
                }
                .stat-value {
                    color: #4A90E2;
                    font-size: 18px;
                    &.explain {
                        color: $color-gold;
                    }
                }
            }
        }

        .languages {
            margin-top: 55px;

            button {
                transition: all .3s ease-out;

                .language-code { display: inline-block };
                .language-name { display: none; }

                &:hover {
                    border: 1px solid $color-primary;
                }
            }
        }

        .ewa-form-group {
            max-width: 40%;
        }

        .qr-code {
            position: absolute;
            right: 105px;
            top: 10px;
        }

        .link-wrapper {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }

        .links {
            display: flex;
            align-items: center;
            column-gap: 15px;
            font-size: 24px;
            width: fit-content
        }
    }

    &-bg-aicreated {
        background-color: rgba(5,5,240,0.05);
    }

    &.hoverable {
        cursor: pointer;
        outline: dotted 3px transparentize($color-primary, .9);
        outline-offset: 4px;
        &:hover{
            background: transparentize($color-primary, .9);
        }
    }
    &.selected {
        background: transparentize($color-danger, .6)!important;
    }

    &.edit-mode {
        padding: 20px;
        font-size: 16px;
        position: relative;
        .info {
            display: inline-block;
            margin-right: 10px;
        }
        .expand {
            display: flex;
            align-items: center;
            justify-content: middle;

            position: absolute;
            top: 0;
            right: 0;

            height: 100%;

            border-left: solid 1px;
            padding: 0 10px;

            background-color: white;
            transition: all .4s;

            i:before {
                font-size: 20px;
            }

            &:hover {
                i:before {
                    font-weight: bold;
                }
            }
        }

        .ewa-form-group {
            margin: 0;
        }

        .ewa-form-imgdrop {
            height: inherit;
        }

        .ewa-form-imgdrop-preview-img {
            position: absolute;
            width: 50px;
            height: 50px;
            top: -30px;
            right: 25px;
        }
    }

    &.flex-wrapper {
        display: flex;
        justify-content: space-between;
    }
}

.learning-card,
.learning-item {
    button {
        &.exercise {
            &.difficulty {
                padding: 1rem 2rem;
                display: inline-block;
                margin-left: 5px;

                &.active {
                    background: $color-primary;
                    color: white;
                }
            }
        }
        &.difficulty {
            padding: 1rem 2rem;
            display: inline-block;

            &.active {
                background: $color-primary;
                color: white;
            }
        }
    }

    .actions {
        cursor: pointer;

        position: absolute;
        top: 0;
        right: 0;

        border-left: solid 1px #F0F3F6;

        width: 96px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        opacity: .5;
        transition: all .3s ease-out;

        &:hover { opacity: 1 };

        .action {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            cursor: pointer;
            padding-top: 15px;
            transition: background .3s;
            margin: 10px 0;

            &:hover {
                background-color: $color-fill;
                color: #000;
            }

            i {
                padding: 2px;
                font-size: 20px;
                margin: 0;
            }
        }
    }
}

.spinner {
  animation-name: spin;
  animation-duration: 1700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
