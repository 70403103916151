.ewa-btn {
    &_primary {
        color:#fff;
        background: $color-primary;

        &:hover, &:focus {
            background: lighten($color-primary, 10%);
            color:#fff;
        }

        &:active {
            opacity:0.9;
        }

        &:disabled {
            background:rgba(174,184,198,0.2);
            opacity:0.4;
        }

        &.-loading {
            background: transparent;
            border: 1px solid rgba(255,255,255,0.8);
            opacity: 1;

            span {
                opacity:0;
            }

            &:after, &:before {
                border:1px #fff solid;
            }

            &:after {
                border-color:rgba(255,255,255,0.8);
            }

            &:before {
                border-color:rgba(255,255,255,0.5);
                animation-name:ewa-btn-loading-2;
            }
        }
    }

    &_secondary {
        @extend .ewa-btn_primary;

        background:$color-primary;
        color:#fff;

        &:hover, &:focus {
            background: lighten($color-primary, 10%);
        }

        &:disabled {
            cursor:default;
            background: lighten($color-primary, 15%);
        }

        &.-loading {
            background:$color-primary;
        }

        &.-gold {
             background:rgba($color-gold, 0.8);

            &:hover, &:focus {
                background:rgba($color-gold, 0.9);
            }

            &:active {
                background:rgba($color-gold, 0.9);
            }
        }

        &.-column {
            margin-top: 5px;
        }

        &.-success {
            background: darken($color-success, 25%);

             &:hover, &:focus {
                background:lighten($color-success, 10%);
            }
        }
    }

    &_default {
        background:$color-fill;
        color:$color-text;

        &:hover, &:focus {
            background:darken($color-fill, 5%);
            color:$color-text;
        }

        &.-loading {
            background:$color-fill;
        }

        &.-primary {
            color:$color-primary;

            &:hover, &:focus {
                color:$color-primary;
            }
        }
    }

    &_search {
        color:$color-primary;

        &:hover, &:focus {
            color:lighten($color-primary, 20%);
        }
    }

    &_round {
        background:$color-muted;
        color:#A9AFBC;


        &:disabled {
            background:$color-muted !important;
            opacity:0.2;
        }

        &:hover, &:focus {
            background:darken($color-muted, 5%);
        }

        &:active {
            background:darken($color-muted, 8%);
        }

        &.-info {
            background:$color-info;
            color:$color-secondary;

            &:hover, &:focus {
                background:darken($color-info, 5%);
            }

            &:active {
                background:darken($color-info, 10%);
            }
        }

        &.-danger {
            background:rgba($color-danger, 0.1);
            color: #fff;

            &:hover, &:focus {
                background:$color-danger;
                color:#fff;
            }

            &:active {
                background:rgba($color-danger, 0.7);
                color:#fff;
            }
        }

        &.-grey {
            background-color: rgba($color-grey, 0.2);

            &:hover, &:focus {
                background-color: rgba($color-grey, 0.4);
            }

            &:active {
                background:rgba($color-gold, 0.4);
                color:#fff;
            }

            span {
                color: $color-smooth;
            }
        }

        &.-gold {
            background:rgba($color-gold, 0.1);

            &:hover, &:focus {
                background:rgba($color-gold, 0.2);
            }

            &:active {
                background:rgba($color-gold, 0.2);
            }

            span {
                color: $color-gold;
            }
        }

        &.-error {
            background:$color-error;
            color:#fff;

            &:hover, &:focus {
                background:lighten($color-error, 3%);
            }

            &:active {
                background:lighten($color-error, 5%);
            }
        }

        &.-loading {
            background:$color-muted !important;

            &:after, &:before {
                border:1px #A9AFBC solid;
            }

            &:after {
                border-color:rgba(0,0,0,0.3);
            }

            &:before {
                border-color:rgba(0,0,0,0.1);
            }
        }
    }

    &_rounded {
        background:$color-success;
        color:#fff;

        &:hover, &:focus {
            background:lighten($color-success, 3%);
        }

        &:active {
            background:lighten($color-success, 5%);
        }

        &.-danger {
            background:rgba($color-danger, 0.5);
            color: #fff;

            &:hover, &:focus {
                background:$color-danger;
                color:#fff;
                outline:none;
            }

            &:active {
                background:rgba($color-danger, 0.7);
                color:#fff;
            }
        }

        &.-success {
            background:rgba($color-success, 0.5);
            color: #fff;

            &:hover, &:focus {
                background:rgba($color-success, 0.8);
            }

            &:active {
                background:rgba($color-success, 0.86);
            }
        }

        &.-hint {
            background: $color-disabled;
            color: #fff;
            outline: 2px solid rgba($color-success, 2);

            &:hover, &:focus {
                background:darken($color-disabled, 5%);
            }

            &:active {
                background:darken($color-disabled, 8%);
            }
        }

        &.-restore {
            background: $color-disabled;
            color: #fff;

            &:hover, &:focus {
                background:darken($color-disabled, 5%);
            }

            &:active {
                background:darken($color-disabled, 8%);
            }
        }

        &.-loading {
            background:rgba($color-success, 0.7) !important;

            i, span {
                opacity:0;
            }

            &:after, &:before {
                border:1px #fff solid;
            }

            &:after {
                border-color:rgba($color-success,0.6);
            }

            &:before {
                border-color:rgba($color-success,0.5);
            }
        }

        &.-active {
            opacity: 1;
        }
    }

    &_word {
        background:$color-fill;
        color:$color-smooth;

        &:hover, &:focus {
            background:darken($color-fill, 1%);
        }

        &:active {
            background:darken($color-fill, 2%);
        }
    }
}
