.ewa-form {
    margin: -25px 0;

    &-actions {
        cursor: pointer;
        padding: 5px;
        display: inline-block;
        font-weight: bold;
    }

    &-add_translation {
        text-align: center;
    }

    &-group {
        position:relative;
        margin: 25px 0;

        &-addon {
            position:absolute;
            height:8px;
            width:8px;
            top:50%;
            right:0;
            margin:-5px 0 0 0;

            color:$color-text;
            font-size:8px;

            &.-success {
                color:$color-success;
            }
        }

        &-remove {
            position:absolute;
            height:8px;
            width:8px;
            top:50%;
            right:0;
            margin:-6px 0 0 0;

            color:$color-danger;
            font-size:8px;

            &:after {
                @include icon-styles;
                content: icon-char(times);
            }

            &:hover, &:focus {
                color:darken($color-danger, 10%);
                text-decoration:none;
            }
        }

        &-inline-conditions {
            .conditions-group {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                line-height: 20px;
                flex: 1;

                input, .description {
                    flex: 1;
                    line-height: 20px;
                    font-size: 14px;
                    text-align: center;
                }


                input {
                    flex: 1;
                    width: auto;
                    max-width: 35px;
                    padding: 5px;
                    margin-top: 3px;
                }

                select { max-width: 80px; }

                .description {
                    flex-wrap: nowrap;
                    width: 30%;
                    font-size: 14px;
                    padding: 0 5px;
                    color: darken($color-fill, 100%);
                    font-weight: bold;

                    &.small {
                        max-width: 50px;
                    }
                }
            }
        }

        &-inline {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            &.-gap {
                row-gap: 20px;
                column-gap: 20px;
            }

            &.-top {
                column-gap: 30px;
                align-items: flex-start;
            }
        }

        &-full-width {
            width: 100%;

            .word-row {
                display: flex;
                justify-content: space-between;
            }

            .word-model {
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                color: $color-primary;
                &:hover {
                    color: $color-warning;
                }
            }
        }

        &.scrollable {
            margin: 10px;
            max-height: 60vh;
            overflow-y: auto;

            &.tiny {
                height: min-content;
                max-height: 205px;
            }
        }

        &-center{
            height: 300px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            row-gap: 10px;
        }

        &-center-full-height{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            row-gap: 10px;
        }

        &-highlight{
            background-color: rgba(0, 0, 0, .05);
            padding: 5px;
            border-radius: 10px;
        }

        &-current-profiles {
            padding: 10px 0;
            border-bottom: 1px solid #ddd;

            h4 {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: bold;
            }

            span {
                font-weight: 200;
                color: rgba(0, 0, 0, 0.4);
            }

            ul {
                list-style-type: none;
                padding-left: 0;
            }

            li {
                margin-bottom: 5px;
                padding: 5px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 3px;
            }

            .lang-text {
                font-weight: bolder;
                color: $color-success;
            }

            .courses-view-text {
                font-weight: bolder;
                color: black;
                text-decoration: underline;
            }
        }
    }

    &-modal{
        &-between{
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            min-height: 315px;
        }
        &-center{
            justify-content: center;
            display: flex;
            flex-direction: column;
            min-height: 315px;
        }
    }

    .ewa-form-error-group {
        position:relative;
        margin: 25px 0;
        font-weight: bold;
        color: darken($color-danger, 20%);
    }

    .ewa-form-select {
        font-size: 16px;
        font-weight: 400;
        padding: 5px 0;
        color: rgba(0, 0, 0, .6);
        margin-top: 8px;

        &-conditions {
            display: flex;
            flex-flow: row nowrap;
            font-size: 16px;
            color: rgba(0, 0, 0, .6);
            font-weight: 400;
            justify-content: space-between;
            padding: 5px 0;

            span:first-child {
                width: 75%;
            }

            .action {
                padding: 0 5px;
                cursor: pointer;

                &.active {
                    cursor: default;
                    user-select: none;
                    color: $color-success;
                }
            }
        }

        .label {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            vertical-align: middle;
        }
    }

    &.email-form {
        display: inline-block;
        width: 600px;
        margin-top: 50px;
        margin-left: 20px;

        textarea {
            width: 100%;
        }

        .mail-subject {
            height: 60px;
        }

        .mail-html {
            height: 600px;
            overflow-x: none;
        }
    }

    &-input {

        width: 100%;
        padding: 15px 0;

        background: none;
        border: none;
        border-bottom: 1px rgba(221, 222, 224, 0.3) solid;

        font-size: 16px;

        transition: all 0.2s;

        &:hover {
            border-bottom-color: rgba(221, 222, 224, 0.5);
        }

        &:focus {
            background: none;
            border-bottom-color: rgba(221, 222, 224, 1);
        }

        &:invalid, &.-error {
            outline: none;

            border-bottom-color: rgba($color-error, 0.5);
        }

        &:disabled {
            color: gray;
            border-bottom-color: rgba(221, 222, 224, 0.1);

            &::-webkit-input-placeholder {
                color:rgba(143,150,166,0.6);
            }
            &::-moz-placeholder {
                color:rgba(143,150,166,0.6);
            }
            &:-moz-placeholder {
                color:rgba(143,150,166,0.6);
            }
            &:-ms-input-placeholder {
                color:rgba(143,150,166,0.6);
            }
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &.-transparent {
            padding:0;
            border:0;

            color:$color-smooth;
            font-size:22px;
            font-weight:normal;
            letter-spacing:0.2px;

            &:disabled {
                color:$color-fill;
                pointer-events: none;
            }
        }

        &.-annotation {
            height: 23px;
            transition: all 0.2s ease-in-out;

            &:focus {
                height: 300px;
            }
        }
    }

    &-file-picker-label {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        max-width: 150px;
        font-size: 12px;
        font-weight: 500;
        border: none;
        margin: 5px;
        padding: 10px 15px;
        border-radius: 3px;
        background-color: $color-primary;
        color: #fff;
        transition: background-color 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }


    &-textarea {
        height: 100px;
        border: solid 1px #ccc;
        border-radius: 10px;
        padding: 15px;
        resize: none;
    }
    &-tagsinput {

        &.-disabled .bootstrap-tagsinput{
            border-bottom-color: rgba(221, 222, 224, 0.1);

            input{
                pointer-events: none;

                &::-webkit-input-placeholder {
                    color:rgba(143,150,166,0.6);
                }
                &::-moz-placeholder {
                    color:rgba(143,150,166,0.6);
                }
                &:-moz-placeholder {
                    color:rgba(143,150,166,0.6);
                }
                &:-ms-input-placeholder {
                    color:rgba(143,150,166,0.6);
                }
            }
        }

        .bootstrap-tagsinput {
            @extend .ewa-form-input;
            padding: 10px 0;

            .twitter-typeahead {
                display:inline-block !important;
            }

            .tt-menu {
                margin:4px 0 0 0 !important;
            }

            .tag {
                position: relative;
                display: inline-block;
                padding: 3px 0;
                margin: 2px 0;

                font-size: 16px;
                letter-spacing: 0;

                &:after {
                    content: ", "
                }

                &:nth-last-child(2) {
                    &:after {
                        display: none;
                    }
                }

                span {
                    cursor: pointer;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;


                    background: $color-danger;
                    opacity: 0;

                    &:hover {
                        opacity: 0.1;
                    }
                }
            }

            input {
                margin: 4px 0;
                padding: 0;
                border: 0;
                background: none;
                font-size: 16px;
                letter-spacing: 0;
                vertical-align: baseline !important;
            }

            &.focus {
                @extend .ewa-form-input:focus;

                .tag {
                    background: $color-fill;

                    &:nth-last-child(2) {
                        &:after {
                            display: inline;
                        }
                    }
                }
            }
        }

        &.-filled {
            input {
                &::-webkit-input-placeholder {
                    color:transparent;
                }
                &::-moz-placeholder {
                    color:transparent;
                }
                &:-moz-placeholder {
                    color:transparent;
                }
                &:-ms-input-placeholder {
                    color:transparent;
                }
            }
        }
    }

    &-selectinput {
        background-color: #fff;
        background-image: none;
        border: none;
        border-bottom: 1px solid hsla(220, 5%, 87%, .3);
        box-shadow: none;
        height: 34px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        width: 100%;
    }

    &-imgdrop {
        height: 192px;
        border-radius: 3px;
        text-align: center;

        &-inner {
            display: table-cell;
            vertical-align: middle;
        }

        &-upload {
            position: relative;
            display: table;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background: $color-fill;
            border: 1px $color-fill solid;
            color: $color-smooth;
            transition: background 0.2s;

            &-inner {
                display: table-cell;
                vertical-align: middle;
            }

            &-input {
                opacity: 0;

                input {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                }
            }

            &-icon {
                font-size: 26px;
            }

            &-text {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 15px;

                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.23px;
            }

            &:hover {
                background: darken($color-fill, 2%);
            }
        }

        &-preview {
            display: none;
            position: relative;
            height: 100%;

            &-img {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                height: 100%;
                width: 100%;
            }

            &-actions {
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                left: 0;
                bottom: 0;
                height: 42px;
                padding: 11px 10px;

                a, label {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.23px;
                    line-height: 23px;

                    i {
                        display: inline-block;
                        margin: -1px 10px 0 0;
                        opacity: 0.6;
                        font-size: 23px;
                        vertical-align: middle;
                    }

                    &:hover, &:focus {
                        opacity: 0.8;
                        text-decoration: none;
                    }
                }
            }
        }

        &.-error &-upload {
            border: 1px $color-error dashed;
            color: $color-error;
        }

        &.-dragover &-upload {
            background: darken($color-fill, 2%);
            border: 1px $color-disabled dashed;
            color: $color-smooth;
        }

        &.-loaded &-upload {
            display: none;
        }

        &.-loaded &-preview {
            display: block;
        }
    }

    &-radio {
        width: 100%;
        padding: 15px 0;

        background: none;
        border: none;
        border-bottom: 1px rgba(221, 222, 224, 0.3) solid;

        font-size: 16px;

        transition: all 0.2s;

        &-title {
            color:$color-smooth;
            display:inline-block;
            vertical-align:middle;
        }

        &-items {
            float:right;
            margin:0 -7px;

            label {
                cursor:pointer;
                margin:0 7px;

                input {
                    position: absolute;
                    padding: 0;
                    margin: 0;
                    z-index: -999;
                }

                span {
                    position:relative;
                    display:inline-block;
                    color:$color-smooth;
                    font-size:13px;
                    font-weight:normal;
                    line-height:1;
                    letter-spacing:0;
                    vertical-align:middle;
                    transition: color 0.1s;

                    &:before {
                        content:"\25CF";
                        font-family: "Times New Roman";
                        display:inline-block;
                        width:14px;
                        height:14px;
                        margin:-1px 6px 0 0;

                        color:transparent;
                        line-height:1;
                        vertical-align:top;

                        border:2px $color-smooth solid;
                        border-radius:100%;

                        transition: all 0.1s;
                    }

                    &:after {
                        content:"";
                        display:block;
                        position:absolute;
                        width:6px;
                        height:6px;
                        top:3px;
                        left:4px;
                        background:$color-success;
                        border-radius:100%;
                        opacity:0;
                        transform:scale(0);
                        transition:all 0.2s;
                    }
                }

                input:checked + span {
                    color:$color-success;

                    &:before {
                        border-color:$color-success;
                    }

                    &:after {
                        opacity:1;
                        transform:scale(1);
                    }
                }
            }
        }

        &:after {
            content:"";
            display:block;
            clear:both;
        }

        &:hover {
            border-bottom-color: rgba(221, 222, 224, 0.5);
        }

        &.-error {
            outline: none;
            border-bottom-color: rgba($color-error, 0.5);
        }

        &.-disabled {
            color: gray;
            border-bottom-color: rgba(221, 222, 224, 0.1);
        }
    }

    &-checkbox, &-selectgroup {
        cursor: pointer;
        display: inline-block;
        padding: 0;
        margin: 0;
        width: 100%;
        @include user-select(none);

        input[type="checkbox"] {
            position: absolute;
            padding: 0;
            margin: 0;
            z-index: -999;
        }

        span {
            position: relative;
            display: inline-block;
            width: 26px;
            height: 14px;
            margin: 0 10px 0 0;

            background: $color-disabled;
            border-radius: 20px;
            vertical-align: middle;

            transition: all 0.2s ease-in-out 0.1s;

            &:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                margin: 0 2px 2px 2px;
                background: #fff;
                border-radius: 100%;
                line-height: 10px;
                vertical-align: middle;

                transition: all 0.2s;
            }
        }

        input:checked + span {
            background: $color-primary;

            &:before {
                transform: translateX(12px);
            }
        }

        b {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            vertical-align: middle;
        }

        .aside-input {
            display: inline-block;
            width: auto;
            height: 20px;
            margin: 0;
            padding: 0;
            position: absolute;
            vertical-align: middle;
            font-size: 13px;
            text-align: right;
            border: none;

            &.right-side {
                right: 0;
            }
        }
    }

    &-selectgroup  {
        b {
            margin-right: 1.5rem;
        }

        i {
            cursor: pointer;
            margin: 0 0.4rem;
            vertical-align: middle;
            font-size: 16px;
            color: #ccc;
            border: 1px solid transparent;
            border-radius: 1px;
            padding: 0.5rem;

            &:hover {
                border-color: #00000070;
            }

            &.active, &.active:hover  {
                cursor: default;
                color: #00000070;
                border-color: transparent;
            }
        }
    }

    &-caption {
        margin: 0 0 25px 0;

        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
    }

    &-text {
        padding: 5px 0 0 0;

        &.-right {
            text-align: right;
        }

        &.-disabled {
            opacity: 0.65;
        }

        &-large {
            font-size: 16px;
        }

        &-pre {
            font-size: 16px;
            max-width: 100%;
            white-space: pre;
            text-align: left;
            overflow: scroll;
            padding: 20px 0;
        }
    }

    &-helper-text {
        padding-top: 10px;
        font-size: 12px;
        color:rgba(0, 0, 0, 0.4);
    }

    &-title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
    }

    &-submit {
        &-error {
            margin: 0 0 12px 0;

            color: $color-error;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.3px;
            text-align: center;

            i {
                margin: 0 10px 0 0;
            }

            &.-hidden {
                display: none;
            }
        }

        margin: 70px 0 0 0;
    }

    &-notice {
        margin:5px 0 0 0;

        &.-success {
            color:$color-success;
        }

        &.-error {
            color:$color-error;
        }
    }

    &-return {
        margin: 25px 0 0 0;
    }

    &-confirm {
        width:100%;
        display:table;
        margin: 25px 0;

        color:$color-smooth;
        font-size:13px;
        font-weight:normal;
        letter-spacing:0;

        &-col {
            display:table-cell;
            vertical-align:middle;

            &.-right {
                text-align:right;
            }

            &.-top {
                padding-top:5px;
                vertical-align:top;
            }
        }

        h3 {
            color:$color-text;
            padding:0;
            margin:0 0 7px 0;
            font-size:20px;
            font-weight:normal;
        }
    }

    &_fulluploader {
        position: relative;
        display: table;
        width: 100%;
        height: 100%;

        border: 2px $color-fill dashed;
        border-radius: 2px;

        &-inner {
            display: table-cell;
            vertical-align: middle;
        }

        &-input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            cursor: pointer;
            background: #000;
            opacity: 0;

            transition: opacity 0.3s;

            input {
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }

            &:hover {
                opacity: 0.02;
            }
        }
    }

    .timerange-group {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        label {
            flex: 2;
        }

        input {
            flex: 1;
            text-align: center;
            font-size: 18px;
            padding: 5px 0;
            line-height: 20px;
        }
    }

    .richTextEditorContainer {
      padding: 25px 0;
    }

    &-content-center{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &-grid {
        display: grid;
        grid-template-rows: repeat(3, 0.8fr); /* 3 rows */
        grid-template-columns: repeat(3, 0.8fr); /* 3 columns */
        gap: 15px;
        padding: 20px;

        &.-gap-20 {
            margin: 10px 0;
            padding: 0px;
            gap: 20px;
        }
    }

    &-checkbox-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        label {
            font-size: 10px;
            font-weight: 500;
            margin: 0;
        }
        input[type='checkbox'] {
            margin: 0;
        }
    }
}
