$color-primary: #FF9800;
$color-primary-kids: #FF5E42;
$color-secondary: #FAFBFC;

$color-fill: #EBEFF5;
$color-muted: #F1F3F8;
$color-smooth: #8F96A6;
$color-disabled: #A1A7B4;
$color-grey: #DFE4EB;
$color-gold: #FAC244;

$color-error: #FD98A7;
$color-info: #F2FCFF;
$color-success: #4CAF50;
$color-warning: #FFEB3B;
$color-danger: $color-error;

$color-bg: #FAFBFC;
$color-text: #181A25;

$color-link: #0BBDF3;
$color-link-hover: lighten($color-link, 5%);

$color-highlight-info: transparentize($color-success, .9);
$color-highlight-stats: transparentize($color-gold, .9);

$color-highlight-default: transparentize(#FFE0B2, .7)
